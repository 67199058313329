#Feature2_0 .ant-row > .ant-col > .kx7e7tyzles-editor_css {
  font-size: 48px;
}
#Feature2_0 .ant-row > .ant-col > .kx7e7xavg6u-editor_css {
  font-size: 24px;
  color: rgb(0, 115, 209);
}
#Content13_0 .title-wrapper > .kx7e9vbvt5-editor_css {
  font-size: 48px;
  line-height: 1.5;
}
#Content13_0 .title-wrapper > .kx7ea29i04-editor_css {
  font-size: 24px;
  line-height: 1.5;
  color: rgb(0, 115, 209);
}
#Content13_0 .title-wrapper > .kx7ea95auj-editor_css {
  line-height: 1.5;
}
