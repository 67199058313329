#Feature4_0.kx7d149ybma-editor_css {
  background-clip: padding-box;
}
#Content13_0.kx7daap44cm-editor_css {
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
