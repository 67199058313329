#Banner2_1 .home-page > .banner2-text-wrapper > .kx7erc3r78q-editor_css {
  font-size: 28px;
}
#Banner2_1 .home-page > .banner2-text-wrapper > .kx7eraslrr8-editor_css {
  line-height: 1;
}

#Banner2_1 .bg-image {
  background-image:url('../../assets/about_background.jpg');
}

#Banner2_1 .banner2-page {
  top:0;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

#Banner2_1 .banner2-page .banner2-text-wrapper {
  width:40%;
}

#Content13_0 .title-wrapper .title-h1 {
  position:relative;
  max-width:1200px;
  margin:0 auto;
}

#Content13_0 .title-wrapper .title-h1 > span > span {
  position:relative;
  z-index:2;
  padding:0 20px;
}

#Content13_0 .title-wrapper .title-h1::before {
  content: ' ';
  position:absolute;
  background:#efefef;
  height:2px;
  top:50%;
  left:0;
  width:100%;
  z-index:1;
}
