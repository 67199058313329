#Banner2_0 .home-page > .banner2-text-wrapper > .kwvrbnudc08-editor_css {
  font-size: 56px;
  line-height:1;
}
#Banner2_0 .banner-anim-elem > .home-page > .kwvrc6vwl2-editor_css {
  width: auto;
}
#Banner2_0 .banner2-text-wrapper .banner2-content {
  font-size:28px;

}
#Banner2_0 .home-page > .banner2-text-wrapper > .kwvrbnudc08-editor_css>span>p {
  margin-bottom:0.5em !important;
}

#Banner2_0 .bg0 {
  background: url("../../assets/banner_01.jpeg") center;
}

@media screen and (max-width: 720px) {
  #Banner2_0 .banner-anim-elem > .home-page > .kwvrc6vwl2-editor_css {
    width: auto;
  }
}

#Feature3_0 .kwvro0vbej-editor_css {
  justify-content: center;
}

#Feature3_0 .kwvro0vbej-editor_css .content3-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Feature3_0 .kwvro0vbej-editor_css .content3-block .content3-title {
  text-align: center;
}

#Feature3_0 .ant-row > .ant-col > .kwvs0uamn37-editor_css {
}
#Pricing0_0 .ant-row > .ant-col > .kwvsivycal-editor_css {
  font-size: 14px;
}
#Pricing0_0 .kwvsjt0d5gt-editor_css {
}
#Footer1_0.kwvsljizpq-editor_css {
  background-color: #fbfbfb;
}
#Footer1_0 .footer1 > .kwvsl8yq3o-editor_css {
  justify-content: center;
}
#Footer1_0 .ant-row > .ant-col > .kwvsnzfs0wa-editor_css {
  color: rgb(64, 64, 64);
}
#Footer1_0 .ant-row > .ant-col > .kwvslpe5d0a-editor_css {
  color: rgb(64, 64, 64);
}
#Footer1_0 .ant-col > .slogan > .kwvslryy8v9-editor_css {
  margin: 20px 0px 0px;
  padding: 24px 0px 0px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  width:140px;
}

.header3-menu .ant-menu a {
  width:140px;
  text-align: center;
  font-weight: bold;
}

.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
  display:none;
}
