body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: 20%;
  max-width: 1200px;
  margin: auto;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 400px;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 56px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-weight: lighter;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 414px) {
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.pricing0-wrapper .pricing0 {
  min-height: 370px;
  padding: 0 24px;
  display: flex;
  align-items: flex-end;
}
.pricing0-wrapper .pricing0-img-wrapper {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
  display: block;
  width: 100%;
  max-width: 560px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img img {
  display: block;
}
.pricing0-wrapper .pricing0-text-wrapper {
  min-height: 320px;
  padding: 0 40px;
  max-width: 560px;
  margin-bottom: 32px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  position: relative !important;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  font-size: 24px;
  font-weight: normal;
  color: #404040;
  margin: 72px auto 16px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content {
  font-size: 12px;
  color: #666;
  line-height: 1.5;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-pricing {
  font-size: 36px;
  color: #404040;
  margin: 32px 0 24px;
}
@media screen and (max-width: 767px) {
  .pricing0-wrapper {
    min-height: 720px;
  }
  .pricing0-wrapper .pricing0 {
    display: block;
  }
  .pricing0-wrapper .pricing0-img-wrapper {
    padding: 0;
    text-align: center;
    margin-top: 24px;
  }
  .pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
    display: inline-block;
    width: 80%;
    margin: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper {
    height: auto;
    text-align: center;
    padding: 0;
    max-width: 100%;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    width: 100%;
    top: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
  margin-top: 60px;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kwvrbnudc08-editor_css {
  font-size: 56px;
  line-height: 1;
}
#Banner2_0 .banner-anim-elem > .home-page > .kwvrc6vwl2-editor_css {
  width: auto;
}
#Banner2_0 .banner2-text-wrapper .banner2-content {
  font-size: 28px;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .kwvrbnudc08-editor_css > span > p {
  margin-bottom: 0.5em !important;
}
#Banner2_0 .bg0 {
  background: url("../../assets/banner_01.jpeg") center;
}
@media screen and (max-width: 720px) {
  #Banner2_0 .banner-anim-elem > .home-page > .kwvrc6vwl2-editor_css {
    width: auto;
  }
}
#Feature3_0 .kwvro0vbej-editor_css {
  justify-content: center;
}
#Feature3_0 .kwvro0vbej-editor_css .content3-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
#Feature3_0 .kwvro0vbej-editor_css .content3-block .content3-title {
  text-align: center;
}
#Pricing0_0 .ant-row > .ant-col > .kwvsivycal-editor_css {
  font-size: 14px;
}
#Footer1_0.kwvsljizpq-editor_css {
  background-color: #fbfbfb;
}
#Footer1_0 .footer1 > .kwvsl8yq3o-editor_css {
  justify-content: center;
}
#Footer1_0 .ant-row > .ant-col > .kwvsnzfs0wa-editor_css {
  color: #404040;
}
#Footer1_0 .ant-row > .ant-col > .kwvslpe5d0a-editor_css {
  color: #404040;
}
#Footer1_0 .ant-col > .slogan > .kwvslryy8v9-editor_css {
  margin: 20px 0px 0px;
  padding: 24px 0px 0px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  width: 140px;
}
.header3-menu .ant-menu a {
  width: 140px;
  text-align: center;
  font-weight: bold;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}
